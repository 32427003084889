export default {
  props: ["nameScreen"],
  data() {
    return {
      texts: "",
      menu: false,
      sCategoryId: '',
      aCategory:[],
      sSubCategoryId: "",
      aSubCategory:[],
      sSearch: "",
      dates: null,
      dateStart: null,
      dateEnd: null,
    };
  },
  mounted() {
    this.$store.commit("setSearch", "");
    // this.$store.commit("setStartDate", null);
    // this.$store.commit("setEndDate", null);
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
    this.getCatalogo();
  },
  methods: {
    setDates() {
      this.dateStart = this.dates[0];
      this.dateEnd = this.dates[1];
    },
    focusSearch() {
      this.$refs.sSearch.focus();
    },
    addArticle() {
      this.$store.commit("setDialogAddArticle", true);
    },
    filterArticle() {
      this.$store.commit("setDialogAddArticle", true);
    },
    getCatalogo(){
      this.aSubCategory = []
      this.sSubCategoryId = ''

      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          // iPageNumber: this.iCurrentPage,
          // iItemsPerPage: this.iItemsPerPage,
          // sSearch: this.sSearch,
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.aCategory = response.data.results; 
          if(this.selectLanguage =='sp'){
            this.aCategory.unshift({sName:'Todos',sCategoryId:''})    
            this.aSubCategory.unshift({sSubcategoryName:'Todos',sSubcategoryId:''}) 
          }else{
            this.aCategory.unshift({sName:'All',sCategoryId:''})    
            this.aSubCategory.unshift({sSubcategoryName:'All',sSubcategoryId:''}) 
          }
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    getSubCatalogo(){      
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sCategoryId: this.sCategoryId,
        },
      })
        .then((response) => {
          this.aSubCategory = response.data.results;   
          if(this.selectLanguage =='sp'){
            this.aSubCategory.unshift({sSubcategoryName:'Todos',sSubcategoryId:''}) 
          }else{
            this.aSubCategory.unshift({sSubcategoryName:'All',sSubcategoryId:''}) 

          }
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    dateStart: function () {
      // this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      // this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
    sCategoryId: function(){
      this.$store.commit("setsCategoryId",this.sCategoryId);
      this.$store.commit("refresher", true);
    },
    sSubCategoryId: function(){
      this.$store.commit("setsSubCategoryId",this.sSubCategoryId);
      this.$store.commit("refresher", true);
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
        this.getCatalogo();

      }
    },
  },
};